import './App.css';
import { Wallet, ethers } from "ethers";
import { useState } from "react";
import Web3 from 'web3';
import ERC20 from "./ERC20.js";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { act } from 'react-dom/test-utils';


// npm run start

function App() {

  const [account, setAccount] = useState("");

  const [connectedProp, setConnected] = useState("");

  const [connected, setConnectedBool] = useState(false);

  // const apikey = "";

  const autoText = async () => {
    setConnected("Please click on 'Connect'")
  };

  useEffect(() => {
    autoText()
  }, []);


  const connect = async () => {
    if (typeof window.ethereum !== "undefined") {

      console.log("MetaMask installed")
      console.log("updated")
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts[0]);
      console.log(accounts[0])
      setConnected("")
      setConnectedBool(true)
      console.log("d")
      console.log(connected)
    } else {
      console.log("Please install metamask.");
      setConnected("Please install MetaMask.")
      setConnectedBool(false);
      console.log(connected)
    }
  };






  const claimShiba = async () => {





    if (typeof window.ethereum !== "undefined") {

      if (connected) {
        console.log("connected")
        console.log(connected)

        console.log("MetaMask installed");
        setConnected("");

        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        console.log(accounts[0])

        let provider = window.ethereum;
        const web3 = new Web3(provider);

        //  const nodeProvider = 'https://eth-mainnet.g.alchemy.com/v2/' + apikey;
        //  const customHttpProvider = new ethers.providers.JsonRpcProvider(nodeProvider);

        const contractAddress = '0x39217f9C630f555937F85dBd264b1068ab532D80';
        const myWalletAddress = account;

        const contract = new web3.eth.Contract(
          ERC20.abi,
          contractAddress
        );

        const nameOfCA = await contract.methods.name().call(
        )

        console.log("Name of token: " + nameOfCA)

        const symbol = await contract.methods.symbol().call(

        )
        console.log("Symbol: " + symbol)

        const claimShiba = await contract.methods.shibaReward().send({ from: account })
          .once('transactionHash', function (hash) { console.log("Claiming Shiba Rewards... | TX Hash: " + hash) })
          .then(function (receipt) {
            console.log("Successfully claimed Shiba rewards");
            alert("Successfully claimed Shiba rewards");
          })
          .catch(e => {
            if (e.code === 4001) {
              console.log("rejected")
            }
          });


        const maBalanceInWEI = await contract.methods.balanceOf(account).call()

        console.log("Balance in WEI: " + maBalanceInWEI)

        const decimals = 1000000000000000000
        const balanceWithDecimals = maBalanceInWEI / decimals

        console.log("Balance: " + Math.round(balanceWithDecimals))

      } else {
        console.log("not connected")
        console.log(connected)
      }




    } else {
      alert('Please install MetaMask.');
      setConnected("Please install MetaMask.");
      console.log("Please install metamask.");
    }





  };




  const claimETH = async () => {





    if (typeof window.ethereum !== "undefined") {

      if (connected) {
        console.log("connected")
        console.log(connected)



        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        console.log(accounts[0])

        let provider = window.ethereum;
        const web3 = new Web3(provider);

        // CODE:
        //    const nodeProvider = 'https://eth-mainnet.g.alchemy.com/v2/' + apikey;
        //    const customHttpProvider = new ethers.providers.JsonRpcProvider(nodeProvider);

        const contractAddress = '0x39217f9C630f555937F85dBd264b1068ab532D80';
        const myWalletAddress = account;

        const contract = new web3.eth.Contract(
          ERC20.abi,
          contractAddress
        );

        const nameOfCA = await contract.methods.name().call(
        )

        console.log("Name of token: " + nameOfCA)

        const symbol = await contract.methods.symbol().call(

        )
        console.log("Symbol: " + symbol)


        const claimETH = await contract.methods.ethReward().send({ from: account })
          .once('transactionHash', function (hash) { console.log("Claiming ETH Rewards... | TX Hash: " + hash) })
          .then(function (receipt) {
            console.log("Successfully claimed ETH rewards");
            alert("Successfully claimed ETH rewards");
          })
          .catch(e => {
            if (e.code === 4001) {
              console.log("rejected")
            }
          });


        const maBalanceInWEI = await contract.methods.balanceOf(account).call()

        console.log("Balance in WEI: " + maBalanceInWEI)

        const decimals = 1000000000000000000
        const balanceWithDecimals = maBalanceInWEI / decimals

        console.log("Balance: " + Math.round(balanceWithDecimals))

        console.log("MetaMask installed");
        setConnected("");


      } else {
        console.log("not connected")
        console.log(connected)
      }





    } else {
      alert('Please install MetaMask.');
      setConnected("Please install MetaMask.");
      console.log("Please install metamask.");
    }





  };





  return (

    <div className="container">

      <Helmet>
        <meta charSet="utf-8" />
        <title>SHIBETH DAPP</title>
        <meta name="SHIBETH DAPP" content="CLaim your rewards." />
      </Helmet>

      <button onClick={connect} className="connectBtn">Connect</button>

      <div className="containerBox">


        <div className="box">
          <div className="innerBox">
            <p className="walletText">Your Wallet: {account} {connectedProp}</p> <br />
            <button onClick={claimShiba} className='btn'>Claim SHIBA Rewards</button>
            <button onClick={claimETH} className='btn'>Claim ETH Rewards</button>
          </div>
        </div>



      </div>
    </div>





  );
}

export default App;


